const jquery = require("jquery");
window.$ = window.jQuery = jquery;

const PAGE_TRANSITION_TIME = 1100

$(window).on("load", function () {
	$("#page-loader-overlay").addClass("hide");
});

// $(document).on("click", "a", function (evt) {
// 	evt.preventDefault(); // Prevent navigation
// 	const href = $(this).attr("href");
// 	// Animate-In our overlay
// 	$("#page-loader-overlay").removeClass("hide");
// 	setTimeout(() => window.location = href, PAGE_TRANSITION_TIME);
// });

$("#navbar a").mouseover(function () {
	if ($(this).hasClass("flex-grow")) return
	$("#navbar a.current").removeClass("flex-grow");
});

$("#navbar a").mouseleave(function () {
	$("#navbar a.current").addClass("flex-grow");
});

// $("sl-resize-observer").on("sl-resize", function (e) {
// 	$("#navbar").width(e.detail.entries[0].contentRect.width);
// })